<template>
  <div class="card-event">
    <h3 v-if="title" v-html="title" class="title title--h3-big"></h3>
    <div class="card-event__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CardEvent',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .card-event {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px 16px;
      margin-bottom: 32px;
    }

    :slotted(.card-event__item-title) {
      font: $fira-20-M;
      margin-bottom: 8px;
    }

    :slotted(.card-event__item-value) {
      font: $fira-16;
    }

    @media (max-width: $laptop) {
      &__content {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
