<template>
  <div :class="classContainer">
    <div class="title-block-top" :class="{ 'title-block-top_mini': miniIndentation }">
      <div :class="['title-block-top__first', classTitleBlock]">
        <h2 class="title title--h2 title--analytics-detail">{{ title }}</h2>
        <div v-if="subtitle || subtitleNumber" :class="['list-info-in-count', classListInfo]">
          <slot name="subtitles">
            <li>
              {{ subtitle }}<span>{{ subtitleNumber }}</span>
            </li>
          </slot>
        </div>
      </div>
      <div class="title-block-top__second">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageTitle',
    props: {
      classContainer: {
        type: String,
        default: 'container-page big container-page--no-indentation',
      },
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        default: '',
      },
      subtitleNumber: {
        type: [Number, String],
        default: '',
      },
      classTitleBlock: {
        type: String,
        default: '',
      },
      classListInfo: {
        type: String,
        default: '',
      },
      miniIndentation: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .list-info-in-count {
    display: flex;
    align-items: flex-start;
    padding: 0;

    :deep(&.list-info-in-count--m0) {
      margin-bottom: 0 !important;
    }

    &:deep(li) {
      font: $fira-16-M;
      margin-right: 24px;
      list-style: none;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }

      span {
        font: $fira-16-M;
        background: linear-gradient(90deg, #0078c8 0%, #01a39d 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-left: 12px;
      }
    }

    @media (max-width: $tablet) {
      margin-bottom: 24px;
    }

    @media (max-width: $laptop) {
      flex-wrap: wrap;
      margin-bottom: 16px;

      &:deep(li) {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
