<template>
  <div class="event-card export-page">
    <PageTitle title="Карточка мероприятия по ВЭС" mod="mb-16">
      <template v-slot:button>
        <ButtonComponent
          @click="downloadPDF"
          class="event-card__export-button button-redesign--download-icon-fill button-redesign--mobile-full"
        >
          <IconComponent name="download-array" />Скачать PDF
        </ButtonComponent>
      </template>
    </PageTitle>
    <div class="container-page big">
      <CardEvent>
        <div class="card-event__item">
          <p class="card-event__item-title">Субъект РФ</p>
          <p class="card-event__item-value">{{ cardData.rfRegion || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Страна партнер</p>
          <p class="card-event__item-value">{{ cardData.partnerCountry || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Дата проведения</p>
          <p class="card-event__item-value">{{ $momentFormat(cardData.startEventDate, 'DD MMMM YYYY') || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Место проведения</p>
          <p class="card-event__item-value">{{ cardData.eventLocation || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Наименование мероприятия</p>
          <p class="card-event__item-value">{{ cardData.name || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Описание мероприятия</p>
          <p class="card-event__item-value">{{ cardData.description || '-' }}</p>
        </div>
      </CardEvent>

      <CardEvent title="Информация о&nbsp;заключенных соглашениях">
        <template v-for="item in cardData.ferAgreement" :key="item.id">
          <div class="card-event__item">
            <p class="card-event__item-title">Вид соглашения</p>
            <p class="card-event__item-value">{{ item.name || '-' }}</p>
          </div>
          <div class="card-event__item">
            <p class="card-event__item-title">Сумма по соглашению</p>
            <p class="card-event__item-value">{{ item.sum ? formatCurrency(item.sum) + ' млн руб.' : 'Нет данных' }}</p>
          </div>
        </template>
      </CardEvent>

      <CardEvent title="Участники мероприятия">
        <div class="card-event__item">
          <p class="card-event__item-title">Российская делегация</p>
          <p class="card-event__item-value">{{ cardData.russianDelegation || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Иностранная делегация</p>
          <p class="card-event__item-value">{{ cardData.foreignDelegation || '-' }}</p>
        </div>
        <div class="card-event__item">
          <p class="card-event__item-title">Контактное лицо</p>
          <p class="card-event__item-value">{{ cardData.contactPerson || '-' }}</p>
        </div>
      </CardEvent>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import html2pdf from 'html2pdf.js';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import Constants from '@/common/constants.js';
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import CardEvent from '../components/events/CardEvent.vue';
  import { EVENTS_VES_ACTIONS_NAME, EVENTS_VES_GETTERS_NAME } from '../store/events/name';

  export default {
    name: 'EventsCardVES',
    mixins: [text],
    components: {
      PageTitle,
      CardEvent,
      ButtonComponent,
      IconComponent,
    },
    data() {
      return {
        id: this.$route.params.id,
      };
    },
    async created() {
      const res = await this.getEventCard({ id: this.id });
      if (!res?.success) this.$router.push({ name: 'EventsVES' });
    },
    computed: {
      ...mapGetters({
        cardData: EVENTS_VES_GETTERS_NAME.getCardDataEvent,
      }),
    },
    methods: {
      ...mapActions({
        getEventCard: EVENTS_VES_ACTIONS_NAME.getEventCard,
      }),

      downloadPDF() {
        let exportContainer = document.querySelector('.export-page');
        exportContainer = exportContainer.cloneNode(true);
        if (exportContainer) {
          exportContainer.classList.add('exportPdf');
          const opt = {
            margin: 2,
            filename: 'Карточка_мероприятия_по_ВЭС.pdf',
            image: { type: 'png', quality: 1 },
            html2canvas: {
              windowWidth: 1920,
              dpi: 192,
              scale: 1,
              letterRendering: true,
              useCORS: true,
            },
            jsPDF: {
              unit: 'mm',
              format: 'a3',
              orientation: 'portrait',
              align: 'center',
              baseline: 'middle',
            },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          };

          html2pdf()
            .set(opt)
            .from(exportContainer)
            .save()
            .then(() => {
              exportContainer.classList.remove('exportPdf');
            });
        }
      },

      formatCurrency(value) {
        return this.formatNumber(value / Constants.THOUSAND, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .event-card {
    &.exportPdf {
      .event-card__export-button {
        display: none;
      }

      :deep(.title) {
        overflow: visible;
      }
    }
  }
</style>
